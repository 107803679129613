import React, { useState, useEffect } from 'react';
import { node, shape, func } from 'prop-types';
import { LoggerContext } from './Context';

const getLogger = (logger) => {
  if (!logger) return console;

  return {
    error: (message) => {
      return logger.log({ level: 'error', message });
    },
    warn: (message) => {
      return logger.log({ level: 'warn', message });
    },
    info: (message) => {
      return logger.log({ level: 'info', message });
    },
    debug: (message) => {
      return logger.log({ level: 'debug', message });
    },
    log: (message) => {
      return logger.log({ level: 'info', message });
    }
  };
};

const Logger = ({ children, logger }) => {
  return (
    <LoggerContext.Provider value={getLogger(logger)}>
      {children}
    </LoggerContext.Provider>
  );
};

Logger.propTypes = {
  children: node.isRequired,
  logger: shape({
    info: func,
    warn: func,
    error: func,
    debug: func
  })
};

Logger.defaultProps = {
  logger: null
};

export { Logger };

import React from 'react';

const CONTEXT_KEY = Symbol.for('@thd-olt-component-react/logger/Context');
const globalSymbols = Object.getOwnPropertySymbols(global);
const hasContext = (globalSymbols.indexOf(CONTEXT_KEY) > -1);

if (!hasContext) {
  global[CONTEXT_KEY] = React.createContext();
}

export const LoggerContext = global[CONTEXT_KEY];

import React from 'react';
import {
  ApolloProvider, ApolloClient, HttpLink, InMemoryCache, disableFragmentWarnings, ApolloLink
} from '@apollo/client';
import PropTypes from 'prop-types';
import { getClientTraceId, initializeTraceID } from '@thd-olt-functional/utils';
import typeDefs from './typesDefs.graphql';
import {
  getRelativeAPIPath,
  dataIdFromObject,
  typePolicies,
  prepareQueryForCache
} from './util';
import possibleTypes from '../shapes/possibleTypes';

let client;

disableFragmentWarnings();

const cache = new InMemoryCache({
  possibleTypes,
  dataIdFromObject,
  typePolicies
});

if (typeof window !== 'undefined' && window.__APOLLO_STATE__) {//eslint-disable-line
  cache.restore(window.__APOLLO_STATE__);//eslint-disable-line
}
const DataProviderClient = ({
  authHeaders,
  host,
  children,
  debug,
  headers,
  link,
  addTypename,
  clientOverride,
  credentials = 'include',
  federation = false,
}) => {
  // Useful for progressive hydration (using the same client in another tree).
  if (clientOverride) {
    return (
      <ApolloProvider client={clientOverride}>
        {children}
      </ApolloProvider>
    );
  }
  // eslint-disable-next-line no-param-reassign
  headers = {
    ...headers,
    'X-Parent-Trace-Id': window.THD_PARENT_TRACE_ID || ''
  };
  if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
    window.THD_TRACE_ID = initializeTraceID();
    window.LIFE_CYCLE_EVENT_BUS.trigger('data-sources.trace', {
      parentTraceId: window.THD_PARENT_TRACE_ID,
      traceId: window.THD_TRACE_ID,
    });
  }
  const result = /env=(\w+)/.exec(window.location.search);
  const envParam = result && result[1];
  const linkOpts = {
    uri: getRelativeAPIPath({ federation, host }),
    fetch: (uri, options) => {
      const body = JSON.parse(options.body);
      const params = [`opname=${body.operationName}`];
      if (envParam) {
        params.push(`env=${envParam}`);
      }
      // eslint-disable-next-line
      options.headers['X-Cloud-Trace-Context'] = getClientTraceId();
      window.THD_TRACE_ID = options.headers?.['X-Cloud-Trace-Context'];
      if (options?.headers['X-current-url'] !== window.location.pathname) {
        // eslint-disable-next-line
        options.headers = options?.headers || {};
        // eslint-disable-next-line
        options.headers['X-current-url'] = window.location.pathname;
      }
      return fetch(`${uri}?${params.join('&')}`, options);
    },
    headers,
    credentials,
  };

  const httpLink = new HttpLink(linkOpts);

  const apolloLink = link || httpLink;

  if (typeof addTypename !== 'undefined') {
    cache.addTypename = addTypename;
  }

  const authLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext() || {};
    const customerToken = authHeaders?.['x-thd-customer-token'];
    if (context.withAuth && customerToken) {
      operation.setContext({
        ...context,
        headers: {
          ...context.headers,
          authorization: `Bearer ${customerToken}`
        }
      });
    }
    return forward(operation);
  });

  const ssoLink = new ApolloLink((operation, forward) => {
    const context = operation.getContext() || {};
    const ssoToken = authHeaders?.['x-thd-sso'];
    if (context.withSSO && ssoToken) {
      operation.setContext({
        ...context,
        headers: {
          'x-thd-sso': ssoToken
        }
      });
    }
    return forward(operation);
  });

  client = new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, ssoLink, apolloLink]),
    connectToDevTools: debug,
    typeDefs,
  });

  if (debug && !window.__APOLLO_CLIENT__) {
    window.__APOLLO_CLIENT__ = client;
  }

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

DataProviderClient.propTypes = {
  authHeaders: PropTypes.shape({
    'x-thd-customer-token': PropTypes.string,
    'x-thd-sso': PropTypes.string,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  credentials: PropTypes.string,
  debug: PropTypes.bool,
  host: PropTypes.string,
  headers: PropTypes.shape({}),
  federation: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  link: PropTypes.any,
  addTypename: PropTypes.bool,
  clientOverride: PropTypes.shape({}),
};

DataProviderClient.defaultProps = {
  authHeaders: null,
  credentials: 'include',
  debug: false,
  host: null,
  headers: {},
  federation: false,
  link: null,
  addTypename: true,
  clientOverride: null,
};

DataProviderClient.persistCache = () => {
  return Promise.resolve({});
};

DataProviderClient.getClient = () => {
  return client;
};

DataProviderClient.writeQueryToCache = (queryInformation, options) => {
  if (!client) {
    console.log('no data provider client found');
    return;
  }
  client.writeQuery(prepareQueryForCache(queryInformation, options));
};

export default DataProviderClient;
